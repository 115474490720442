html,
body {
  margin: 0;
  font-family: lato;
  height: 100%;

  * {
    outline: none;
  }
}

body::-webkit-scrollbar,
.mat-select-panel::-webkit-scrollbar,
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  transition: height 0.15s ease-out;
}

body::-webkit-scrollbar-track,
.mat-select-panel::-webkit-scrollbar-track,
::-webkit-scrollbar-track {
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb,
.mat-select-panel::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  opacity: 0.1;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.3);
  box-shadow: rgba(255, 255, 255, 0.3) 0 0 0 1px;
}

body:hover::-webkit-scrollbar,
.mat-select-panel:hover::-webkit-scrollbar {
  height: 0.6em;
  transition: height 0.25s ease-in;
}

body:hover::-webkit-scrollbar-thumb,
.mat-select-panel:hover::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.3);
  box-shadow: rgba(255, 255, 255, 0.3) 0 0 0 1px;
}

body::-webkit-scrollbar-thumb:hover,
.mat-select-panel::-webkit-scrollbar-thumb:hover {
  opacity: 1;
  background: rgba(0, 0, 0, 0.55);
}

footer {
  padding: 0 15px;
  position: fixed;
  bottom: 0;
  left: 0;

  small {
    font-size: 0.7rem;
    text-transform: uppercase;
  }
}

.vis-tooltip {
  position: absolute !important;
  z-index: 999999999 !important;
  background: var(--white-color);
  box-shadow: 0 1px 23px rgba(0, 0, 0, 0.2), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 5px;
  font-size: 0.7rem;

  h6 {
    color: #888;
    border-bottom: 1px solid #888;
    padding-bottom: 10px;
  }
}

.hide {
  display: none;
}

.overflow-hidden {
  overflow: hidden;
}

.resize .border {
  position: absolute;
  background: var(--danger);
}

.resize .border-top,
.resize .border-bottom {
  left: 0;
  right: 0;
  height: 1px;
  cursor: n-resize;
}

.resize .border-left,
.resize .border-right {
  top: 0;
  bottom: 0;
  width: 1px;
  cursor: w-resize;
}

.resize .border-left {
  left: 0;
}

.resize .border-right {
  right: 0;
}

.resize .border-top {
  top: 0;
}

.resize .border-bottom {
  bottom: 0;
}

.resize .border-top-left {
  left: 0;
  top: 0;
  height: 4px;
  width: 4px;
  background: transparent !important;
  cursor: se-resize;
  z-index: 999;
}

.resize .border-top-right {
  right: 0;
  top: 0;
  height: 4px;
  width: 4px;
  background: transparent !important;
  cursor: ne-resize;
  z-index: 999;
}

.resize .border-bottom-left {
  left: 0;
  bottom: 0;
  height: 4px;
  width: 4px;
  background: transparent !important;
  cursor: ne-resize;
  z-index: 999;
}

.resize .border-bottom-right {
  right: 0;
  bottom: 0;
  height: 4px;
  width: 4px;
  background: transparent !important;
  cursor: se-resize;
  z-index: 999;
}

.cdk-global-overlay-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  // opacity: 0.75;
}

//for date range selector button color
.md-drppicker {
  padding: 20px !important;
  font-family: var(--font-family) !important;
  font-size: 1em !important;
  max-width: 640px;

  .table-condensed {
    border-spacing: 0px;
  }

  th.month {
    text-transform: uppercase;
    color: var(--text);
    font-size: 14px;
  }

  &.double {
    .calendar {
      max-width: 295px !important;

      th.available.prev,
      th.available.next {
        background: none;
        position: relative;
        vertical-align: middle;
        line-height: 30px;

        &::before {
          font-family: "Font Awesome 5 Free";
          content: "\f104";
          position: absolute;
          color: var(--primary);
          vertical-align: middle;
          top: 50%;
          transform: translateY(-50%);
          height: 100%;
          font-size: 1.3em;
          text-align: center;
        }
      }

      &.left {
        border-right: 1px solid var(--line);

        th {
          // &.next {
          //   display: none;
          // }
        }
      }

      &.right {
        th {
          // &.prev {
          //   display: none;
          // }
        }
      }

      td,
      th {
        border-radius: 0px !important;
        min-width: 40px;
        height: 30px;

        &:not(.month) {
          font-size: 13px;
        }
      }

      td {
        span {
          padding: 0px;
          color: inherit;
          font-size: 13px;
          font-weight: 550 !important;
        }

        &.available:not(.active) {
          &:hover {
            background-color: var(--hover-state) !important;
          }
        }

        &::hover {
          border-radius: 0px !important;
        }

        &.in-range {
          background-color: var(--hover-state) !important;
          border-radius: 0px !important;
          color: var(--text) !important;
        }

        &.active {
          background-color: var(--primary) !important;
          border-radius: 0px !important;
          color: var(--white-color) !important;
        }
      }

      th {
        span {
          padding: 0px;
        }

        &.available:not(.active):not(.prev):not(.next) {
          &:hover {
            background-color: var(--hover-state) !important;
          }
        }

        &::hover {
          border-radius: 0px !important;
        }
      }
    }
  }

  //   td.active,
  //   td.active:hover {
  //     background-color: var(--primary) !important;
  //     border-radius: 0px !important;
  //   }
  .start-date,
  .end-date,
  .in-range {
    color: var(--white-color) !important;

    span {
      opacity: 1 !important;
    }
  }

  .btn {
    color: var(--primary) !important;
    box-shadow: none !important;
    background: transparent !important;
    margin: 0;
    min-width: auto;
    line-height: 25px !important;
  }

  .btn.btn-default::after {
    content: "|";
    color: gray;
    font-size: 19px;
    line-height: 0px;
    margin-left: 13px;
    position: relative;
    top: -1px;
  }
}

.gm-style .gm-style-iw-c {
  border-radius: 5px;

  button {
    top: 0px !important;
    right: 0px !important;
  }
}

.cdk-global-scrollblock {
  position: static !important;
  width: initial !important;
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cccc;
  border-radius: 10px;
}

html {}

/* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: #BCB1B1;
// }
.mat-select-panel {
  position: absolute;
  top: 22px;
  left: 12px;
  font-size: 13px;
  font-weight: 400;
  border-radius: 3px !important;
  border-top-style: none;
}

.mat-select[aria-activedescendant] {
  .mat-select-arrow {
    box-sizing: border-box;
    height: 6px !important;
    width: 6px !important;
    border-left: none !important;
    border-right: 2px solid var(--primary) !important;
    border-top: none !important;
    border-style: solid;
    border-color: var(--primary);
    border-width: 0 2px 2px 0;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
}

.mat-form-field-appearance-outline .mat-form-field-outline-start {
  border-radius: 0px !important;
  border-right-style: none !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-end {
  border-radius: 0px !important;
  border-left-style: none !important;
}

.mat-select-panel ng-animating {
  visibility: hidden;
}

.vacation-modal-dialog .mat-dialog-container {
  padding: 0 !important;
}

.pointsummary-padding-dialog .mat-dialog-container {
  padding: 0 !important;
}

.qrCode-modal-modal-dialog .mat-dialog-container {
  padding: 24px 18px 24px 18px !important;
  overflow: hidden !important;
}

.sort-order-dialog .mat-dialog-container {
  padding: 0 !important;
  overflow: unset !important;
}

.social-share-dialog .mat-dialog-container {
  padding: 0 !important;
  overflow: hidden !important;
}

.mat-form-field.mat-focused .mat-select-arrow {
  transform: rotate(45deg) !important;
}

.sub-user-select-panel.mat-select-panel {
  overflow-y: auto !important;
  max-height: 200px !important;
}

.auto-complete-zone {
  mat-option.option-txt {
    height: 30px;
    font-size: 13px;
  }
}

.filter-nodes-select,
.nodes-select {
  .mat-option {
    padding: 0 16px 0px 40px;
  }

  mat-option {
    .mat-option-pseudo-checkbox {
      color: var(--primary) !important;
      width: 16px !important;
      height: 16px !important;
    }
  }
}


.zone-tile-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
  cursor: pointer;


  .zone-info {
    display: flex;
    justify-content: center;
    align-items: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    padding-left: 5px;

    .zone-name {
      font-size: 12px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      padding-left: 3px;
      max-width: calc(100% - 15px);
    }

    .status {
      width: 10px;
      height: 10px;
      border: 1px solid white;
      background: var(--light-grey);
      border-radius: 100%;
      padding-right: 3px;
    }

    .online {
      background: var(--active-color);
    }
  }
}

.zone-version-labels {
  overflow: hidden;
  white-space: nowrap;
  max-width: calc(100% - 4px);
  display: table;
  font-size: 10px;

  .module-status {
    display: flex !important;
    padding-right: 3px;

    .status {
      width: 10px;
      height: 10px;
      border: 1px solid var(--white-color);
      background: var(--light-grey);
      border-radius: 100%;
      padding-right: 3px;
    }

    .online {
      background: var(--active-color);
    }
  }

  div {
    display: table-cell;
    overflow: hidden;
  }
}

.zone-temp-labels-ctnr {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
  cursor: pointer;

  .zone-temp-labels {
    display: table;
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    /* white-space: nowrap; */
    max-width: calc(100% - 4px);
    padding: 2px;

    div {
      padding: 0 2px;
      border-right: 1px solid;
      display: table-cell;
      overflow: hidden;

    }

    div:last-child {
      border-right: none !important
    }
  }
}

.special-schedule-modal-dialog .mat-dialog-container {
  padding: 0 0 20px 0 !important;
}

.pointerEnable {
  pointer-events: all !important;
}

.pointerDisable {
  pointer-events: none !important;
}

.disableAndBlur {
  pointer-events: none !important;
  opacity: 0.5;
}

.rio-master-control {
  max-width: 90vw !important;
}

.rio-master-control .mat-dialog-container {
  padding: 0 0 35px 0 !important;
  overflow: hidden;
}

.rio-master-control .mat-dialog-title {
  margin: 0 !important;
}

.duo-master-control {
  max-width: 90vw !important;
}

.duo-master-control .mat-dialog-container {
  padding: 0 0 35px 0 !important;
  overflow: hidden;
  border-radius: 5px;
  background-color: #fefefe;
  box-sizing: border-box;
}

.duo-master-control .mat-dialog-title {
  margin: 0 !important;
}

.orange-color {
  color: var(--primary) !important;
  cursor: pointer;
}

.archiveNotes-bg {
  .orange-color {
    color: var(--mid-light-grey) !important;
  }
}

.notestooltip {
  display: inline-flex;
}

.notestooltip .notestooltiptext {
  visibility: hidden;
  background-color: var(--header-text);
  width: auto;
  color: var(--white-color);
  text-align: center;
  border-radius: 3px;
  font-size: 11px;
  padding: 7px 12px;
  position: absolute;
  z-index: 9999999;
  margin-top: -35px;
}

.notestooltip:hover .notestooltiptext {
  visibility: visible;
}

.ccu-verions-menu {
  min-width: 180px !important;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}

.zone-settings__section {
  li {
    list-style: none !important;
    line-height: 2rem !important;
    font-size: 1rem !important;
    color: #888888 !important;
  }
}

.noKVtag {
  .mat-pseudo-checkbox {
    display: none !important;
  }
}

.matTooltip_paste {
  color: var(--white-color);
  border-radius: 4px;
  margin: 11px !important;
  max-width: 250px;
  padding-left: 8px;
  padding-right: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  transform: scale(0);
}

.blocklyDropDownDiv {
  position: fixed !important;
}

.file-upload-mat-dialog-container{
  .mat-dialog-container {
    box-shadow: none;
    border-radius: 8px;
    padding: 0px !important;
    overflow: hidden;
  }
}

.file-preview-container {
  .mat-dialog-container {
    box-shadow: none;
    background: none;
    padding: 0px !important;
  }
}

.filter-tooltip {
  margin: 0px 0px 0px 34px !important;
  left: 15px !important;
}

.reorder-ccu-tooltip {
  margin: 0px !important;
  right: 10px;
  bottom: 5px;
}

.auto-refresh-tooltip {
  bottom: 85px !important;
}

.qrCode-tooltip {
  margin: 0px 0px 0px 5px !important;
  bottom: 10px !important;
}

.sharable-icons-tooltip {
  margin: 0px 0px 0px 5px !important;
  bottom: 3px !important;
}

.common-style-tooltip {
  font-weight: 400 !important;
  font-size: 12px !important;
  background-color: var(--strong-text) !important;
  opacity: 80% !important;
  padding: 10px !important;
  position: relative;
  max-width: 100% !important;
}

.zone-name-tooltip {
  background-color: var(--strong-text) !important;
  min-height: 34px;
  height: auto;
  padding: 10px;
  border-radius: 4px !important;
  font-size: 12px !important;
  opacity: 80% !important;
  font-weight: 400;
  line-height: 14px;
  color: var(--white-color) !important;
  width: 100% !important;
  bottom: 12px;
  display: inline;
  left: 14px;
  position: relative;
}

//Used on Hover of Download-icon in Easy-street(Under checkout report)
.es-download-tooltip-position {
  font-weight: 400 !important;
  font-size: 12px !important;
  background-color: var(--strong-text) !important;
  opacity: 80% !important;
  padding: 10px !important;
  position: relative;
  max-width: 100% !important;
  margin: 7px !important;
}

.pinned-notes-tooltip {
  bottom: 90px !important;
  left: 18px !important;
}

.view-assets-tooltip {
  bottom: 20px !important;
  left: -85px !important;
  max-width: 265px !important;
}


.selected-color-box-alerts {
  width: 9px;
  height: 9px;
  border-radius: 2px;
  margin-right: 5px;
  display: inline-block;
}

.textsize-alerts {
  font-family: Oswald, Regular !important;
  font-weight: 400;
  padding-right: 5px;
  font-size: 10px !important;
  display: inline-block;
  color: var(--light-grey);
}

.alerts-severity-container {
  position: relative;
  top: 2px;
}

.padd-typename {
  padding: 0px !important;
}
#qrPrintLayout {
  display: none;
}


@media print {

  .fs-wrapper {
    display: none !important;
  }

  .alert-container {
    display: none !important;
  }

  puc-qrcode-print-dialog {
    display: none !important;
  }

  puc-qrcode-list-layout {
    display: none !important;
  }

  puc-alert {
    display: none !important;
  }

  .cdk-global-scrollblock {
    position: absolute !important;
    overflow-y: auto !important;
  }

  .qrCode-modal-modal-dialog {
    display: none !important;
  }

  .cdk-overlay-backdrop {
    display: none !important;
  }

  #qrPrintLayout {
    display: block;
  }
}

.VIpgJd-ZVi9od-aZ2wEe-wOHMyf {
  visibility: hidden !important;
}

.gtranslate_wrapper {
  visibility: hidden !important;
}

.fs-header-puc-dropdown .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: var(--primary) !important;
  height: 2px !important;
}

.version-management-container {
  .mat-dialog-container {
      padding-left: 24px !important;
      padding-right: 24px !important;
      padding-top: 24px !important;
      padding-bottom: 24px !important;
  }
}


.version-validation-dialog {
  .mat-dialog-container {
      padding-left: 24px !important;
      padding-right: 24px !important;
      padding-top: 10px !important;
      padding-bottom: 5px !important;
  }
}
.mat-menu-item-submenu-trigger .mat-menu-submenu-icon {
  display: none;
}

//Adding this style here instead of PUC, because it is not taking priority in PUC
.ccu-logLevel-dropdown.mat-select-panel {
  position: relative !important;
  margin: 12px 0 45px;
  min-width: 90% !important;
}

.dashboard-preview-graphics-tooltip {
  top: 0px !important;
}

.dashboard-graphics-tooltip {
  top: 0px !important;
}

#launcher {
  border-radius: 45px !important;
  border: none !important;
  bottom: 25px !important;
  width: 60px !important;
  height: 60px !important;
}